.record {
    transition: transform 0.3s ease-in-out; /* smooth transition for other states */
}

.tilt {
    animation: tilt 1.5s ease-in-out infinite; /* Keeps the hover effect for the icon */
}

@keyframes tilt {
    0% {
        transform: translateY(0) rotate(0deg);
    }
    20% {
        transform: translateY(-10px) rotate(-5deg);
    }
    40% {
        transform: translateY(0) rotate(5deg);
    }
    60% {
        transform: translateY(-5px) rotate(-3deg);
    }
    80% {
        transform: translateY(0) rotate(2deg);
    }
    100% {
        transform: translateY(0) rotate(0deg);
    }
}

.beat {
    opacity: 0; /* Start with the path invisible */
    animation: beat 1.5s ease-in-out infinite; /* Apply the smooth beat animation */
    animation-delay: 0.5s; /* Add delay before the path animation starts */
}

@keyframes beat {
    0% {
        opacity: 0;
        transform: scale(1); /* Start at normal size */
    }
    50% {
        opacity: 1;
        transform: scale(1.05); /* Slightly scale up */
    }
    100% {
        opacity: 1;
        transform: scale(1); /* Return to normal size */
    }
}
