.container {
    background-color: #292929;
    width: 100%;
    overflow: hidden;
    padding: 12px 0;
}

.scrollContainer {
    display: flex;
    width: 100%;
    animation: scroll 20s linear infinite;
    gap: 80px; /* Add gap between each logo */
    align-items: center;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.logoItem {
    flex-shrink: 0;
    opacity: 0.5;
}

.logoItem img {
    object-fit: contain;
    width: 100px; /* Default width for mobile */
}

@media (min-width: 768px) {
    .logoItem img {
        width: 100px; /* Width for desktop */
    }
}
