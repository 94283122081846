.list {
    transition: transform 0.3s ease-in-out; /* smooth transition for other states */
}

.beat {
    display: inline-block;
    animation: beat 1.5s ease-in-out infinite; /* Apply the beat animation */

    svg {
        width: 100px;
        height: 100px;
        transform-origin: center; /* Ensures the scale happens from the center */
    }
}

@keyframes beat {
    0% {
        transform: scale(1); /* Original size */
    }
    50% {
        transform: scale(1.12); /* Slightly expand */
    }
    100% {
        transform: scale(1); /* Return to original size */
    }
}

.point {
    display: inline-block;
    animation: bounce 2s ease-in-out infinite; /* Apply the bounce animation */
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0); /* Starting and ending position */
    }
    25% {
        transform: translateY(-6px); /* Move upwards slightly */
    }
    50% {
        transform: translateY(0); /* Return to original position */
    }
    75% {
        transform: translateY(6px); /* Move downwards slightly */
    }
}

.draw {
    fill: white; /* Make sure the path is not filled */

    animation: drawFromLeft 2s ease-out infinite; /* Apply the left-to-right drawing animation and loop it */
}

@keyframes drawFromLeft {
    0% {
        clip-path: inset(0 100% 0 0); /* Initially hide the path */
    }
    50% {
        clip-path: inset(0 0 0 0); /* Halfway reveal the path */
    }
    100% {
        clip-path: inset(0 100% 0 0); /* Hide the path again */
    }
}
