.alerts {
    transition: transform 0.3s ease-in-out;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.ring {
    animation: ring 0.5s ease-in-out infinite;
}
