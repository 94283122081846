.close {
    transition: transform 0.3s ease-in-out;
}

@keyframes shake {
    0% {
        transform: rotate(10deg);
    }
    25% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(10deg);
    }
}

.shake {
    animation: shake 0.5s ease-in-out infinite;
}
