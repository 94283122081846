.share {
    transition: transform 0.3s ease-in-out; /* smooth transition for other states */
}

.swing {
    animation: swing 1.5s ease-in-out infinite;
    transform-origin: center center;
}

@keyframes swing {
    0% {
        transform: rotate(-15deg);
    }
    50% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(-15deg);
    }
}
