.heroTitle {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (min-width: 768px) {
        gap: 0.5rem;
    }

    @media (min-width: 1024px) {
        gap: 0rem;
    }
}

.displayWord {
    background: linear-gradient(to left, #6a1c9a, #9b4dca);
    font-weight: bolder;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    overflow: hidden;
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-out;
    opacity: 1;
}

.cursorSpace {
    color: white;
    font-weight: normal;
    display: inline-block;
    width: 10px;
}

.blink {
    animation: blink;
}
