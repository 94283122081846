.brief {
    transition: transform 0.3s ease-in-out;
}

@keyframes bounce {
    0%,
    100% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(5px, -5px);
    }
}

.bounce {
    animation: bounce 2s infinite ease-in-out;
    overflow: visible;

    path {
        fill: white;
    }
}

.draw {
    fill: white;

    animation: drawFromLeft 4s ease-in-out infinite;
}

@keyframes drawFromLeft {
    0% {
        clip-path: inset(0 100% 0 0);
    }
    50% {
        clip-path: inset(0 0 0 0);
    }
    100% {
        clip-path: inset(0 100% 0 0);
    }
}
