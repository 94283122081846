.lottieContainer {
    position: absolute;
    inset: 0;
    margin-top: -140px;
    margin-bottom: -100px;
}

@media (max-width: 1024px) {
    .lottieContainer {
        margin-top: -130px;
        margin-bottom: -90px;
    }
}

@media (max-width: 768px) {
    .lottieContainer {
        margin-top: -70px;
        margin-bottom: -50px;
    }
}
